import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';
import Password from 'SharedComponents/password/components/password';

const FormView = ({ onSubmit }) => (
  <div className="bvs-card twofa__container">
    <Form onSubmit={onSubmit} id="password-form">
      {({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <p className="form-password__enter-password">{t('javascript.twofa.settings.enter_account_password')}</p>
          <div>
            <Field
              name="password"
              component={Password}
              label={t('javascript.twofa.settings.account_password')}
              placeholder={t('javascript.twofa.settings.account_password')}
              withToggle
              showError={({ submitError }) => !!submitError}
            />
          </div>
          <Button
            primary
            label={t('javascript.twofa.settings.account_password_button')}
            disabled={submitting || !values.password}
            className="bvs-button is-primary container-input-group__enter-button"
          />
        </form>
      )}
    </Form>
  </div>
);

FormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FormView;
